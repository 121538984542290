.command-palette {
  position: relative;
  z-index: 10;
}
.command-palette *:not(.mg-omnisearch) {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: 0 solid;
}
.command-palette .command-palette-content {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  margin: 0;
  line-height: inherit;
}
.command-palette :after,
.command-palette :before {
  box-sizing: border-box;
  border: 0 solid;
  --tw-content: "";
}
.command-palette hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
.command-palette abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.command-palette h1,
.command-palette h2,
.command-palette h3,
.command-palette h4,
.command-palette h5,
.command-palette h6 {
  font-size: inherit;
  font-weight: inherit;
}
.command-palette a {
  color: inherit;
  text-decoration: inherit;
}
.command-palette b,
.command-palette strong {
  font-weight: bolder;
}
.command-palette code,
.command-palette kbd,
.command-palette pre,
.command-palette samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  font-size: 1em;
}
.command-palette small {
  font-size: 80%;
}
.command-palette sub,
.command-palette sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: initial;
}
.command-palette sub {
  bottom: -0.25em;
}
.command-palette sup {
  top: -0.5em;
}
.command-palette table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
.command-palette button,
.command-palette input,
.command-palette optgroup,
.command-palette select,
.command-palette textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
.command-palette button,
.command-palette select {
  text-transform: none;
}
.command-palette [type="button"],
.command-palette [type="reset"],
.command-palette [type="submit"],
.command-palette button {
  -webkit-appearance: button;
  background-color: initial;
  background-image: none;
}
.command-palette :-moz-focusring {
  outline: auto;
}
.command-palette :-moz-ui-invalid {
  box-shadow: none;
}
.command-palette progress {
  vertical-align: initial;
}
.command-palette ::-webkit-inner-spin-button,
.command-palette ::-webkit-outer-spin-button {
  height: auto;
}
.command-palette [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
.command-palette ::-webkit-search-decoration {
  -webkit-appearance: none;
}
.command-palette ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.command-palette summary {
  display: list-item;
}
.command-palette blockquote,
.command-palette dd,
.command-palette dl,
.command-palette figure,
.command-palette h1,
.command-palette h2,
.command-palette h3,
.command-palette h4,
.command-palette h5,
.command-palette h6,
.command-palette hr,
.command-palette p,
.command-palette pre {
  margin: 0;
}
.command-palette fieldset {
  margin: 0;
  padding: 0;
}
.command-palette legend {
  padding: 0;
}
.command-palette menu,
.command-palette ol,
.command-palette ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.command-palette textarea {
  resize: vertical;
}
.command-palette input::-moz-placeholder,
.command-palette textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
.command-palette input::placeholder,
.command-palette textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
.command-palette [role="button"],
.command-palette button {
  cursor: pointer;
}
.command-palette :disabled {
  cursor: default;
}
.command-palette audio,
.command-palette canvas,
.command-palette embed,
.command-palette iframe,
.command-palette img,
.command-palette object,
.command-palette svg,
.command-palette video {
  display: block;
  vertical-align: middle;
}
.command-palette img,
.command-palette video {
  max-width: 100%;
  height: auto;
}
.command-palette [hidden] {
  display: none;
}
.command-palette *:not(.mg-omnisearch),
.command-palette :after,
.command-palette :before {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: var(--tw-empty, /*!*/ /*!*/);
  --tw-brightness: var(--tw-empty, /*!*/ /*!*/);
  --tw-contrast: var(--tw-empty, /*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty, /*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty, /*!*/ /*!*/);
  --tw-invert: var(--tw-empty, /*!*/ /*!*/);
  --tw-saturate: var(--tw-empty, /*!*/ /*!*/);
  --tw-sepia: var(--tw-empty, /*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty, /*!*/ /*!*/);
  --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.command-palette .pointer-events-none {
  pointer-events: none;
}
.command-palette .fixed {
  position: fixed;
}
.command-palette .absolute {
  position: absolute;
}
.command-palette .relative {
  position: relative;
}
.command-palette .inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.command-palette .right-3 {
  right: 0.75rem;
}
.command-palette .top-1\/2 {
  top: 50%;
}
.command-palette .mt-1 {
  margin-top: 0.25rem;
}
.command-palette .block {
  display: block;
}
.command-palette .flex {
  display: flex;
}
.command-palette .h-\[450px\] {
  height: 450px;
}
.command-palette .h-5 {
  height: 1.25rem;
}
.command-palette .max-h-full {
  max-height: 100%;
}
.command-palette .w-full {
  width: 100%;
}
.command-palette .w-5 {
  width: 1.25rem;
}
.command-palette .w-4 {
  width: 1rem;
}
.command-palette .max-w-xl {
  max-width: 36rem;
}
.command-palette .max-w-md {
  max-width: 28rem;
}
.command-palette .max-w-xs {
  max-width: 20rem;
}
.command-palette .flex-1 {
  flex: 1 1 0%;
}
.command-palette .-translate-y-1\/2 {
  --tw-translate-y: -50%;
}
.command-palette .-translate-y-1\/2,
.command-palette .scale-95 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.command-palette .scale-95 {
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
}
.command-palette .scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
.command-palette .scale-100,
.command-palette .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.command-palette .cursor-default {
  cursor: default;
}
.command-palette .cursor-pointer {
  cursor: pointer;
}
.command-palette .flex-col {
  flex-direction: column;
}
.command-palette .items-start {
  align-items: flex-start;
}
.command-palette .items-center {
  align-items: center;
}
.command-palette .justify-center {
  justify-content: center;
}
.command-palette .justify-between {
  justify-content: space-between;
}
.command-palette .space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.command-palette .space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.command-palette .space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
}
.command-palette .space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
}
.command-palette .space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
}
.command-palette .space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
}
.command-palette .divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.command-palette .overflow-hidden {
  overflow: hidden;
}
.command-palette .overflow-y-auto {
  overflow-y: auto;
}
.command-palette .truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.command-palette .rounded-lg {
  border-radius: 0.5rem;
}
.command-palette .rounded-md {
  border-radius: 0.375rem;
}
.command-palette .border-t {
  border-top-width: 1px;
}
.command-palette .border-b {
  border-bottom-width: 1px;
}
.command-palette .border-none {
  border-style: none;
}
.command-palette .border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}
.command-palette .bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
.command-palette .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.command-palette .bg-gray-200\/50 {
  background-color: rgba(229, 231, 235, 0.5);
}
.command-palette .bg-transparent {
  background-color: initial;
}
.command-palette .bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}
.command-palette .bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.command-palette .from-indigo-900 {
  --tw-gradient-from: #312e81;
  --tw-gradient-to: rgba(49, 46, 129, 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.command-palette .via-indigo-800 {
  --tw-gradient-to: rgba(55, 48, 163, 0);
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to);
}
.command-palette .to-indigo-400 {
  --tw-gradient-to: #818cf8;
}
.command-palette .p-4 {
  padding: 1rem;
}
.command-palette .p-2 {
  padding: 0.5rem;
}
.command-palette .px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
.command-palette .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.command-palette .py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.command-palette .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.command-palette .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.command-palette .px-0 {
  padding-left: 0;
  padding-right: 0;
}
.command-palette .pl-3 {
  padding-left: 0.75rem;
}
.command-palette .text-left {
  text-align: left;
}
.command-palette .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.command-palette .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.command-palette .font-semibold {
  font-weight: 600;
}
.command-palette .font-medium {
  font-weight: 500;
}
.command-palette .leading-tight {
  line-height: 1.25;
}
.command-palette .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.command-palette .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.command-palette .text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.command-palette .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.command-palette .text-white\/80 {
  color: hsla(0, 0%, 100%, 0.8);
}
.command-palette .antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.command-palette .placeholder-gray-500::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}
.command-palette .placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}
.command-palette .opacity-0 {
  opacity: 0;
}
.command-palette .opacity-100 {
  opacity: 1;
}
.command-palette .opacity-50 {
  opacity: 0.5;
}
.command-palette .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.command-palette .outline {
  outline-style: solid;
}
.command-palette .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.command-palette .transition {
  transition-property: color, background-color, border-color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-text-decoration-color,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.command-palette .duration-300 {
  transition-duration: 0.3s;
}
.command-palette .duration-200 {
  transition-duration: 0.2s;
}
.command-palette .ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.command-palette .ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.command-palette .hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.command-palette .hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.command-palette .focus\:border-none:focus {
  border-style: none;
}
.command-palette .focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.command-palette .focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.command-palette .focus\:ring-0:focus,
.command-palette .focus\:ring-1:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.command-palette .focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
}
.command-palette .focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}
@media (prefers-color-scheme: dark) {
  .command-palette .dark\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-divide-opacity));
  }
  .command-palette .dark\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .command-palette .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .command-palette .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .command-palette .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
  }
  .command-palette .dark\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .command-palette .dark\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }
}
